import React, { useState, useRef } from "react";
import "./suggestions.css";
import Assets from "../../assets/assets";

function Suggestion() {
    const [form, setForm] = useState({ name: "", email: "", phone: "", course: "" });
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [otp, setOtp] = useState(["", "", "", ""]);
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const [generatedOtp, setGeneratedOtp] = useState("");
    const [insertId, setInsertId] = useState(null);
    const [loading, setLoading] = useState(false); // Loading state

    const otpRefs = [useRef(), useRef(), useRef(), useRef()];

    const handleChange = (e, index) => {
        const value = e.target.value;
        if (value.length <= 1 && /^[0-9]$/.test(value)) {
            const updatedOtp = [...otp];
            updatedOtp[index] = value;
            setOtp(updatedOtp);

            if (value && index < otp.length - 1) {
                otpRefs[index + 1].current.focus();
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setLoading(true); // Show loader while submitting the form
            submitFormData(form);
            generateOtp();
        }
    };

    const validate = () => {
        let newErrors = {};
        if (!form.name) newErrors.name = "Name is required";
        if (!form.email) {
            newErrors.email = "Email is required";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.email)) {
            newErrors.email = "Invalid email format";
        }
        if (!form.phone) {
            newErrors.phone = "Phone number is required";
        } else if (!/^\d{10}$/.test(form.phone)) {
            newErrors.phone = "Phone number must be 10 digits";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const submitFormData = async (formData) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            course: formData.course,
            campaignName: "2111",
            Source: "WEB"
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        try {
            const response = await fetch("https://api.theleadsense.com/api/v1/admissions/add/lead/", requestOptions);
            const result = await response.json();
            console.log(result);
            if (result.status === 200 && !result.error) {
                setInsertId(result.data.insertId);
                sessionStorage.setItem("insertId", result.data.insertId);
                setIsSubmitted(true);
            } else {
                alert('You are already in our database, so our academic counselors will call you soon.');
            }
        } catch (error) {
            console.error("Error submitting form data:", error);
        } finally {
            setLoading(false); // Hide loader after submission
        }
    };

    const generateOtp = () => {
        const otpCode = Math.floor(1000 + Math.random() * 9000).toString();
        setGeneratedOtp(otpCode);
        console.log("Generated OTP: ", otpCode);
    };

    const verifyOtp = async () => {
        const otpString = otp.join(""); // Join the OTP array into a string
        setLoading(true); // Show loader during OTP verification

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            otp_code: otpString,
            user_id: insertId
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        try {
            const response = await fetch("https://api.theleadsense.com/api/v1/verify_otp", requestOptions);
            const result = await response.json();
            console.log(result);

            if (result.status === 200 && !result.error) {
                setIsOtpVerified(true);
            } else {
                alert("OTP verification failed. Please try again.");
            }
        } catch (error) {
            console.error("Error verifying OTP:", error);
        } finally {
            setLoading(false); // Hide loader after OTP verification
        }
    };

    return (
        <div className="sug_main">
            <div className="suggestions">
                <div className="row">
                    <div className="col-lg-12">
                        <img src={Assets?.Logo} alt="Logo" />
                    </div>
                    <div className="col-lg-12">
                        {!isSubmitted ? (
                            <>
                                <h3>Simply share your details below, and we'll help you find the perfect Online Degree program for you</h3>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="name">Name:</label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            className={`form-control ${errors.name ? "is-invalid" : ""}`}
                                            placeholder="Enter your name"
                                            value={form.name}
                                            onChange={(e) => setForm({ ...form, name: e.target.value })}
                                        />
                                        {errors.name && <small className="error-text">{errors.name}</small>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Email:</label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            className={`form-control ${errors.email ? "is-invalid" : ""}`}
                                            placeholder="Enter your email"
                                            value={form.email}
                                            onChange={(e) => setForm({ ...form, email: e.target.value })}
                                        />
                                        {errors.email && <small className="error-text">{errors.email}</small>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phone">Phone:</label>
                                        <input
                                            type="tel"
                                            id="phone"
                                            name="phone"
                                            className={`form-control ${errors.phone ? "is-invalid" : ""}`}
                                            placeholder="Enter your phone number"
                                            value={form.phone}
                                            onChange={(e) => setForm({ ...form, phone: e.target.value })}
                                        />
                                        {errors.phone && <small className="error-text">{errors.phone}</small>}
                                    </div>
                                    <button type="submit" className="btn btn-primary" disabled={loading}>
                                        {loading ? "Sending OTP..." : "Receive the OTP via call"}
                                    </button>
                                </form>
                                <div className="mt-5">
                                    <h2>Affordable Online Degree Programs</h2>
                                    <p>Looking for affordable online degree programs? You’ve come to the right place.</p>
                                    <p>Access information on over 300 universities with just a click. Explore low-cost online programs that fit your budget. Benefit from easy, zero-cost EMI options to make payments more manageable.</p>
                                </div>
                            </>
                        ) : !isOtpVerified ? (
                            <div>
                                <h3>OTP Verification</h3>
                                <p>A one-time password (OTP) has been sent to you via call. Please enter it below to verify your identity.</p>
                                <div className="otp-input">
                                    {otp.map((digit, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            maxLength="1"
                                            className="otp-field"
                                            value={digit}
                                            onChange={(e) => handleChange(e, index)}
                                            ref={otpRefs[index]}
                                        />
                                    ))}
                                </div>
                                <button className="btn btn-primary mt-5" onClick={verifyOtp} disabled={loading}>
                                    {loading ? "Verifying..." : "Verify OTP"}
                                </button>
                            </div>
                        ) : (
                            <div className="thank-you-message">
                                <h3>Thank You!</h3>
                                <p>Your registration is complete, and your details have been successfully submitted.</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Suggestion;
